<template>
    <div class="main-table__data">
        <Avatar
            :username="columnData.name"
            :src="columnData.avatar"
            :size="30">
        </Avatar>
        {{columnData.name}}
    </div>
</template>

<script>
import Avatar from "vue-avatar";

export default {
    props: {
        columnData: Object,
    },
    components: {
        Avatar
    }
}
</script>

<style scoped>

</style>